var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.FinalInformationPart.title",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              }
            },
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { md: "2" } },
                    [
                      _c("CIcon", {
                        attrs: { name: "cilCalendar", size: "4xl" }
                      })
                    ],
                    1
                  ),
                  _c("CCol", { attrs: { md: "10" } }, [
                    _c("h3", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "components.playroomEditParts.FinalInformationPart.title_1",
                          expression:
                            "'components.playroomEditParts.FinalInformationPart.title_1'"
                        }
                      ]
                    }),
                    _c("p", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "components.playroomEditParts.FinalInformationPart.text_1",
                          expression:
                            "'components.playroomEditParts.FinalInformationPart.text_1'"
                        }
                      ]
                    })
                  ])
                ],
                1
              ),
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { md: "2" } },
                    [_c("CIcon", { attrs: { name: "cilTask", size: "4xl" } })],
                    1
                  ),
                  _c("CCol", { attrs: { md: "10" } }, [
                    _c("h3", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "components.playroomEditParts.FinalInformationPart.title_2",
                          expression:
                            "'components.playroomEditParts.FinalInformationPart.title_2'"
                        }
                      ]
                    }),
                    _c("p", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "components.playroomEditParts.FinalInformationPart.text_2",
                          expression:
                            "'components.playroomEditParts.FinalInformationPart.text_2'"
                        }
                      ]
                    })
                  ])
                ],
                1
              ),
              _vm.ready
                ? _c(
                    "CForm",
                    [
                      _c("ValidatedCheckbox", {
                        attrs: {
                          translationKey: "playroom.acceptLegalTerms",
                          "data-cy": "acceptLegalTerms-field",
                          field: _vm.$v.playroom.acceptLegalTerms,
                          disabled: _vm.savedAcceptLegalTerms,
                          checked: _vm.$v.playroom.acceptLegalTerms.$model
                        },
                        on: {
                          "update:checked": function($event) {
                            return _vm.$set(
                              _vm.$v.playroom.acceptLegalTerms,
                              "$model",
                              $event
                            )
                          }
                        }
                      }),
                      _c("ValidatedCheckbox", {
                        attrs: {
                          translationKey: "playroom.acceptBusinessTerms",
                          "data-cy": "acceptBusinessTerms-field",
                          field: _vm.$v.playroom.acceptBusinessTerms,
                          disabled: _vm.savedAcceptBusinessTerms,
                          checked: _vm.$v.playroom.acceptBusinessTerms.$model
                        },
                        on: {
                          "update:checked": function($event) {
                            return _vm.$set(
                              _vm.$v.playroom.acceptBusinessTerms,
                              "$model",
                              $event
                            )
                          }
                        }
                      }),
                      _c("ValidatedSwitch", {
                        attrs: {
                          translationKey: "playroom.activeByOwner",
                          "data-cy": "activeByOwner-field",
                          field: _vm.$v.playroom.activeByOwner,
                          disabled: _vm.activeByOwnerIsDisabled,
                          checked: _vm.$v.playroom.activeByOwner.$model
                        },
                        on: {
                          "update:checked": function($event) {
                            return _vm.$set(
                              _vm.$v.playroom.activeByOwner,
                              "$model",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value:
                      "components.playroomEditParts.FinalInformationPart.info.box1.title",
                    expression:
                      "'components.playroomEditParts.FinalInformationPart.info.box1.title'"
                  }
                ]
              }),
              _c("CCardBody", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "components.playroomEditParts.FinalInformationPart.info.box1.text"
                    )
                  )
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }