var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "summary-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.SummaryPart.title",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _c(
                "CForm",
                [
                  _c("ValidatedInput", {
                    attrs: {
                      translationKey: "playroom.name",
                      "data-cy": "name-field",
                      field: _vm.$v.playroom.name,
                      value: _vm.$v.playroom.name.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.$v.playroom.name, "$model", $event)
                      },
                      focusChange: _vm.onFocusChange
                    }
                  }),
                  _c("AddressForm", {
                    attrs: {
                      field: _vm.$v.playroom,
                      value: _vm.$v.playroom.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.$v.playroom, "$model", $event)
                      },
                      change: _vm.onChange,
                      focusChange: _vm.onFocusChange
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name: "components.playroomEditParts.SummaryPart.info.box1"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }