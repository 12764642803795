var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.playroomId
    ? _c(
        "div",
        { staticClass: "owner-playroom-edit-check-and-finish" },
        [
          _c("SummaryPart", {
            ref: "summaryPart",
            attrs: { playroomId: _vm.playroomId },
            on: {
              "part-save-status-type-change": function(status) {
                return (_vm.summaryPartSaveStatusType = status)
              }
            }
          }),
          _c("OwnerInformationPart", {
            ref: "ownerInformationPart",
            attrs: { playroomId: _vm.playroomId },
            on: {
              ownerIsManagerChange: _vm.onOwnerIsManagerChange,
              "part-save-status-type-change": function(status) {
                return (_vm.ownerInformationPartSaveStatusType = status)
              }
            }
          }),
          !_vm.ownerIsManager
            ? _c("ManagerInformationPart", {
                ref: "managerInformationPart",
                attrs: { playroomId: _vm.playroomId },
                on: {
                  "part-save-status-type-change": function(status) {
                    return (_vm.managerInformationPartSaveStatusType = status)
                  }
                }
              })
            : _vm._e(),
          _c("FinalInformationPart", {
            ref: "finalInformationPart",
            attrs: { playroomId: _vm.playroomId },
            on: {
              "part-save-status-type-change": function(status) {
                return (_vm.finalInformationPartSaveStatusType = status)
              }
            }
          }),
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c(
                    "CButtonGroup",
                    { staticClass: "navButtons" },
                    [
                      _c(
                        "CButton",
                        {
                          attrs: { color: "secondary" },
                          on: { click: _vm.onBack }
                        },
                        [_vm._v("<")]
                      ),
                      _c("CButton", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value:
                              "pages.owner.playrooms.edit.OwnerPlayroomEditCheckAndFinish.next",
                            expression:
                              "'pages.owner.playrooms.edit.OwnerPlayroomEditCheckAndFinish.next'"
                          }
                        ],
                        staticClass: "saveAndNext",
                        attrs: { color: "primary", disabled: !_vm.canSave },
                        on: { click: _vm.onNext }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }