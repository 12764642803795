var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "owner-information-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.OwnerInformationPart.title",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _c(
                "CForm",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "4" } },
                        [
                          _c("ValidatedSelect", {
                            attrs: {
                              field: _vm.$v.playroom.ownerTitle,
                              value: _vm.$v.playroom.ownerTitle.$model,
                              options: _vm.titleOptions,
                              translationKey: "playroom.ownerTitle"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.ownerTitle,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "4" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.ownerFirstName,
                              value: _vm.$v.playroom.ownerFirstName.$model,
                              translationKey: "playroom.ownerFirstName"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.ownerFirstName,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "4" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.ownerLastName,
                              value: _vm.$v.playroom.ownerLastName.$model,
                              translationKey: "playroom.ownerLastName"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.ownerLastName,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.email,
                              value: _vm.$v.playroom.email.$model,
                              translationKey: "playroom.email",
                              autocomplete: "email",
                              "data-cy": "email"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.email,
                                  "$model",
                                  $event
                                )
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-content",
                                fn: function() {
                                  return [_vm._v("@")]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.phoneNumber,
                              value: _vm.$v.playroom.phoneNumber.$model,
                              translationKey: "playroom.phoneNumber"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.phoneNumber,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.companyName,
                              value: _vm.$v.playroom.companyName.$model,
                              translationKey: "playroom.companyName"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.companyName,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedSelect", {
                            attrs: {
                              field: _vm.$v.playroom.companyLegalType,
                              options: _vm.companyLegalTypeOptions,
                              value: _vm.$v.playroom.companyLegalType.$model,
                              translationKey: "playroom.companyLegalType"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.companyLegalType,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _vm.isCompanyLegalNameDisabled
                        ? _c(
                            "CCol",
                            { attrs: { md: "12" } },
                            [
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.playroom.companyLegalName,
                                  value:
                                    _vm.$v.playroom.companyLegalName.$model,
                                  translationKey: "playroom.companyLegalName"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.companyLegalName,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "CCol",
                        { attrs: { md: "12" } },
                        [
                          _c("AddressForm", {
                            attrs: {
                              field: _vm.$v.playroom,
                              value: _vm.$v.playroom.$model
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom,
                                  "$model",
                                  $event
                                )
                              },
                              change: _vm.onChange,
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "12" } },
                        [
                          _c("ValidatedCheckbox", {
                            attrs: {
                              field: _vm.$v.playroom.ownerIsManager,
                              checked: _vm.$v.playroom.ownerIsManager.$model,
                              translationKey: "playroom.ownerIsManager"
                            },
                            on: {
                              "update:checked": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.ownerIsManager,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name:
                "components.playroomEditParts.OwnerInformationPart.info.box1"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }